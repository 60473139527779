var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _vm.message.from
        ? _c("b-card-header", { staticClass: "email-detail-head" }, [
            _c(
              "div",
              {
                staticClass:
                  "user-details d-flex justify-content-between align-items-center flex-wrap"
              },
              [
                _c("b-avatar", {
                  staticClass: "mr-75",
                  attrs: { size: "48", src: _vm.message.from.avatar }
                }),
                _c(
                  "div",
                  { staticClass: "mail-items" },
                  [
                    _c("h5", { staticClass: "mb-0" }, [
                      _vm._v(" " + _vm._s(_vm.message.from.name) + " ")
                    ]),
                    _c(
                      "b-dropdown",
                      {
                        staticClass: "email-info-dropup",
                        attrs: {
                          variant: "link",
                          "no-caret": "",
                          "toggle-class": "p-0"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button-content",
                              fn: function() {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-small-3 text-muted mr-25"
                                    },
                                    [_vm._v(_vm._s(_vm.message.from.email))]
                                  ),
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronDownIcon",
                                      size: "10"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1431602336
                        )
                      },
                      [
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-sm table-borderless font-small-3"
                          },
                          [
                            _c("tbody", [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "text-right text-muted align-top"
                                  },
                                  [_vm._v(" From: ")]
                                ),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.message.from.email))
                                ])
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "text-right text-muted align-top"
                                  },
                                  [_vm._v(" To: ")]
                                ),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.message.to
                                        .map(function(receiver) {
                                          return receiver.email
                                        })
                                        .join(", ")
                                    )
                                  )
                                ])
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "text-right text-muted align-top"
                                  },
                                  [_vm._v(" Date: ")]
                                ),
                                _c("td", [_vm._v("4:25 AM 13 Jan 2018")])
                              ])
                            ])
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mail-meta-item d-flex align-items-center" },
              [
                _c("small", { staticClass: "mail-date-time text-muted" }, [
                  _vm._v(_vm._s(_vm.formatDate(_vm.message.time)))
                ]),
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      variant: "link",
                      "no-caret": "",
                      "toggle-class": "p-0",
                      right: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function() {
                            return [
                              _c("feather-icon", {
                                staticClass: "ml-50 text-body",
                                attrs: { icon: "MoreVerticalIcon", size: "17" }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2528854530
                    )
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      [
                        _c("feather-icon", {
                          attrs: { icon: "CornerUpLeftIcon" }
                        }),
                        _c("span", { staticClass: "align-middle ml-50" }, [
                          _vm._v("Reply")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "b-dropdown-item",
                      [
                        _c("feather-icon", {
                          attrs: { icon: "CornerUpRightIcon" }
                        }),
                        _c("span", { staticClass: "align-middle ml-50" }, [
                          _vm._v("Forward")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "b-dropdown-item",
                      [
                        _c("feather-icon", { attrs: { icon: "TrashIcon" } }),
                        _c("span", { staticClass: "align-middle ml-50" }, [
                          _vm._v("Delete")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c("b-card-body", { staticClass: "mail-message-wrapper pt-2" }, [
        _c("div", {
          staticClass: "mail-message",
          domProps: { innerHTML: _vm._s(_vm.message.message) }
        })
      ]),
      _vm.message.attachments && _vm.message.attachments.length
        ? _c("b-card-footer", [
            _c("div", { staticClass: "mail-attachments" }, [
              _c(
                "div",
                { staticClass: "d-flex align-items-center mb-1" },
                [
                  _c("feather-icon", {
                    attrs: { icon: "PaperclipIcon", size: "16" }
                  }),
                  _c(
                    "h5",
                    { staticClass: "font-weight-bolder text-body mb-0 ml-50" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.message.attachments.length) +
                          " Attachment" +
                          _vm._s(
                            _vm.message.attachments.length > 1 ? "s" : ""
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-column" },
                _vm._l(_vm.message.attachments, function(attachment, index) {
                  return _c(
                    "b-link",
                    {
                      key: index,
                      class: { "mt-75": index },
                      attrs: { href: attachment.url, target: "_blank" }
                    },
                    [
                      _c("b-img", {
                        staticClass: "mr-50",
                        attrs: { src: attachment.thumbnail, width: "16px" }
                      }),
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-muted font-weight-bolder align-text-top"
                        },
                        [_vm._v(_vm._s(attachment.fileName))]
                      ),
                      _c(
                        "span",
                        { staticClass: "text-muted font-small-2 ml-25" },
                        [_vm._v("(" + _vm._s(attachment.size) + ")")]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }