var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-left" }, [
    _c("div", { staticClass: "sidebar" }, [
      _c("div", { staticClass: "sidebar-content email-app-sidebar" }, [
        _c(
          "div",
          { staticClass: "email-app-menu" },
          [
            _c(
              "div",
              { staticClass: "form-group-compose text-center compose-btn" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(255, 255, 255, 0.15)",
                        expression: "'rgba(255, 255, 255, 0.15)'",
                        modifiers: { "400": true }
                      }
                    ],
                    attrs: { variant: "primary", block: "" },
                    on: {
                      click: function($event) {
                        _vm.$emit("update:shall-show-email-compose-modal", true)
                        _vm.$emit("close-left-sidebar")
                      }
                    }
                  },
                  [_vm._v(" Compose ")]
                )
              ],
              1
            ),
            _c(
              "vue-perfect-scrollbar",
              {
                staticClass: "sidebar-menu-list scroll-area",
                attrs: { settings: _vm.perfectScrollbarSettings }
              },
              [
                _c(
                  "b-list-group",
                  { staticClass: "list-group-messages" },
                  _vm._l(_vm.emailFilters, function(filter) {
                    return _c(
                      "b-list-group-item",
                      {
                        key: filter.title + _vm.$route.path,
                        attrs: {
                          to: filter.route,
                          active: _vm.isDynamicRouteActive(filter.route)
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit("close-left-sidebar")
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-75",
                          attrs: { icon: filter.icon, size: "18" }
                        }),
                        _c(
                          "span",
                          { staticClass: "align-text-bottom line-height-1" },
                          [_vm._v(_vm._s(filter.title))]
                        ),
                        _vm.emailsMeta[filter.title.toLowerCase()]
                          ? _c(
                              "b-badge",
                              {
                                staticClass: "float-right",
                                attrs: {
                                  pill: "",
                                  variant: _vm.resolveFilterBadgeColor(
                                    filter.title
                                  )
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.emailsMeta[filter.title.toLowerCase()]
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c("h6", { staticClass: "section-label mt-3 mb-1 px-2" }, [
                  _vm._v(" Labels ")
                ]),
                _c(
                  "b-list-group",
                  { staticClass: "list-group-labels" },
                  _vm._l(_vm.emailLabel, function(label) {
                    return _c(
                      "b-list-group-item",
                      {
                        key: label.title + _vm.$route.path,
                        attrs: {
                          to: label.route,
                          active: _vm.isDynamicRouteActive(label.route)
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit("close-left-sidebar")
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "bullet bullet-sm mr-1",
                          class: "bullet-" + label.color
                        }),
                        _c("span", [_vm._v(_vm._s(label.title))])
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }