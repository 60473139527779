var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "inherit" } },
    [
      _c("div", {
        staticClass: "body-content-overlay",
        class: { show: _vm.mqShallShowLeftSidebar },
        on: {
          click: function($event) {
            _vm.mqShallShowLeftSidebar = false
          }
        }
      }),
      _c(
        "div",
        { staticClass: "email-app-list" },
        [
          _c(
            "div",
            { staticClass: "app-fixed-search d-flex align-items-center" },
            [
              _c(
                "div",
                { staticClass: "sidebar-toggle d-block d-lg-none ml-1" },
                [
                  _c("feather-icon", {
                    staticClass: "cursor-pointer",
                    attrs: { icon: "MenuIcon", size: "21" },
                    on: {
                      click: function($event) {
                        _vm.mqShallShowLeftSidebar = true
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-content-center justify-content-between w-100"
                },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "input-group-merge" },
                    [
                      _c(
                        "b-input-group-prepend",
                        { attrs: { "is-text": "" } },
                        [
                          _c("feather-icon", {
                            staticClass: "text-muted",
                            attrs: { icon: "SearchIcon" }
                          })
                        ],
                        1
                      ),
                      _c("b-form-input", {
                        attrs: {
                          value: _vm.searchQuery,
                          placeholder: "Search email"
                        },
                        on: { input: _vm.updateRouteQuery }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c("div", { staticClass: "app-action" }, [
            _c(
              "div",
              { staticClass: "action-left" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    attrs: {
                      checked: _vm.selectAllEmailCheckbox,
                      indeterminate: _vm.isSelectAllEmailCheckboxIndeterminate
                    },
                    on: { change: _vm.selectAllCheckboxUpdate }
                  },
                  [_vm._v(" Select All ")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectedEmails.length,
                    expression: "selectedEmails.length"
                  }
                ],
                staticClass: "align-items-center",
                class: { "d-flex": _vm.selectedEmails.length }
              },
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      variant: "link",
                      "no-caret": "",
                      "toggle-class": "p-0",
                      right: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _c("feather-icon", {
                              staticClass: "align-middle text-body",
                              attrs: { icon: "FolderIcon", size: "17" }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function($event) {
                            return _vm.moveSelectedEmailsToFolder("draft")
                          }
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "Edit2Icon" } }),
                        _c("span", { staticClass: "align-middle ml-50" }, [
                          _vm._v("Draft")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function($event) {
                            return _vm.moveSelectedEmailsToFolder("spam")
                          }
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "InfoIcon" } }),
                        _c("span", { staticClass: "align-middle ml-50" }, [
                          _vm._v("Spam")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "b-dropdown-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$route.params.folder !== "trash",
                            expression: "$route.params.folder !== 'trash'"
                          }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.moveSelectedEmailsToFolder("trash")
                          }
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { icon: "TrashIcon" } }),
                        _c("span", { staticClass: "align-middle ml-50" }, [
                          _vm._v("Trash")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-dropdown",
                  {
                    staticClass: "ml-1",
                    attrs: {
                      variant: "link",
                      "no-caret": "",
                      "toggle-class": "p-0",
                      right: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _c("feather-icon", {
                              staticClass: "align-middle text-body",
                              attrs: { icon: "TagIcon", size: "17" }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function($event) {
                            return _vm.updateSelectedEmailsLabel("personal")
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "mr-50 bullet bullet-success bullet-sm"
                        }),
                        _c("span", [_vm._v("Personal")])
                      ]
                    ),
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function($event) {
                            return _vm.updateSelectedEmailsLabel("company")
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "mr-50 bullet bullet-primary bullet-sm"
                        }),
                        _c("span", [_vm._v("Company")])
                      ]
                    ),
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function($event) {
                            return _vm.updateSelectedEmailsLabel("important")
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "mr-50 bullet bullet-warning bullet-sm"
                        }),
                        _c("span", [_vm._v("Important")])
                      ]
                    ),
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function($event) {
                            return _vm.updateSelectedEmailsLabel("private")
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "mr-50 bullet bullet-danger bullet-sm"
                        }),
                        _c("span", [_vm._v("Private")])
                      ]
                    )
                  ],
                  1
                ),
                _c("feather-icon", {
                  staticClass: "cursor-pointer ml-1",
                  attrs: { icon: "MailIcon", size: "17" },
                  on: { click: _vm.markSelectedEmailsAsUnread }
                }),
                _c("feather-icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$route.params.folder !== "trash",
                      expression: "$route.params.folder !== 'trash'"
                    }
                  ],
                  staticClass: "cursor-pointer ml-1",
                  attrs: { icon: "TrashIcon", size: "17" },
                  on: {
                    click: function($event) {
                      return _vm.moveSelectedEmailsToFolder("trash")
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "vue-perfect-scrollbar",
            {
              staticClass: "email-user-list scroll-area",
              attrs: { settings: _vm.perfectScrollbarSettings }
            },
            [
              _c(
                "ul",
                { staticClass: "email-media-list" },
                _vm._l(_vm.emails, function(email) {
                  return _c(
                    "b-media",
                    {
                      key: email.id,
                      class: { "mail-read": email.isRead },
                      attrs: { tag: "li", "no-body": "" },
                      on: {
                        click: function($event) {
                          return _vm.updateEmailViewData(email)
                        }
                      }
                    },
                    [
                      _c(
                        "b-media-aside",
                        { staticClass: "media-left mr-50" },
                        [
                          _c("b-avatar", {
                            staticClass: "avatar",
                            attrs: {
                              size: "40",
                              variant: "primary",
                              src: email.from.avatar
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "user-action" },
                            [
                              _c("b-form-checkbox", {
                                attrs: {
                                  checked: _vm.selectedEmails.includes(email.id)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.toggleSelectedMail(email.id)
                                  }
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  }
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "email-favorite" },
                                [
                                  _c("feather-icon", {
                                    class: {
                                      "text-warning fill-current":
                                        email.isStarred
                                    },
                                    attrs: { icon: "StarIcon", size: "17" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleStarred(email)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("b-media-body", [
                        _c("div", { staticClass: "mail-details" }, [
                          _c("div", { staticClass: "mail-items" }, [
                            _c("h5", { staticClass: "mb-25" }, [
                              _vm._v(" " + _vm._s(email.from.name) + " ")
                            ]),
                            _c("span", { staticClass: "text-truncate" }, [
                              _vm._v(_vm._s(email.subject))
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "mail-meta-item" },
                            [
                              email.attachments.length
                                ? _c("feather-icon", {
                                    attrs: { icon: "PaperclipIcon" }
                                  })
                                : _vm._e(),
                              _vm._l(email.labels, function(label) {
                                return _c("span", {
                                  key: label,
                                  staticClass: "mx-50 bullet bullet-sm",
                                  class:
                                    "bullet-" + _vm.resolveLabelColor(label)
                                })
                              }),
                              _c("span", { staticClass: "mail-date" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDateToMonthShort(email.time, {
                                      hour: "numeric",
                                      minute: "numeric"
                                    })
                                  )
                                )
                              ])
                            ],
                            2
                          )
                        ]),
                        _c("div", { staticClass: "mail-message" }, [
                          _c("p", { staticClass: "text-truncate mb-0" }, [
                            _vm._v(
                              " " + _vm._s(_vm.filterTags(email.message)) + " "
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "div",
                {
                  staticClass: "no-results",
                  class: { show: !_vm.emails.length }
                },
                [_c("h5", [_vm._v("No Items Found")])]
              )
            ]
          )
        ],
        1
      ),
      _c("email-view", {
        class: { show: _vm.showEmailDetails },
        attrs: {
          "email-view-data": _vm.emailViewData,
          "opended-email-meta": _vm.opendedEmailMeta
        },
        on: {
          "close-email-view": function($event) {
            _vm.showEmailDetails = false
          },
          "move-email-to-folder": _vm.moveOpenEmailToFolder,
          "toggle-email-starred": function($event) {
            return _vm.toggleStarred(_vm.emailViewData)
          },
          "update-email-label": _vm.updateOpenEmailLabel,
          "mark-email-unread": _vm.markOpenEmailAsUnread,
          "change-opened-email": _vm.changeOpenedEmail
        }
      }),
      _c(
        "portal",
        { attrs: { to: "content-renderer-sidebar-left" } },
        [
          _c("email-left-sidebar", {
            class: { show: _vm.mqShallShowLeftSidebar },
            attrs: {
              "shall-show-email-compose-modal": _vm.shallShowEmailComposeModal,
              "emails-meta": _vm.emailsMeta
            },
            on: {
              "update:shallShowEmailComposeModal": function($event) {
                _vm.shallShowEmailComposeModal = $event
              },
              "update:shall-show-email-compose-modal": function($event) {
                _vm.shallShowEmailComposeModal = $event
              },
              "close-left-sidebar": function($event) {
                _vm.mqShallShowLeftSidebar = false
              }
            }
          })
        ],
        1
      ),
      _c("email-compose", {
        model: {
          value: _vm.shallShowEmailComposeModal,
          callback: function($$v) {
            _vm.shallShowEmailComposeModal = $$v
          },
          expression: "shallShowEmailComposeModal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }